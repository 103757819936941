<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      title="Edit Reason"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <!-- Field: Reason Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="reasonData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Tag -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- Reason Tag -->
              <validation-provider
                #default="validationContext"
                name="tag"
                rules=""
              >
                <b-form-group
                  :label="$t('Tag')"
                  label-for="tag"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="reasonData.tag"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tagOptions"
                    :reduce="val => val.value"
                    multiple
                    input-id="tag"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Branch - branch_id|branch_name -->
            <b-col
              cols="12"
              md="4"
            >

              <validation-provider
                #default="validationContext"
                name="branch"
                rules=""
              >
                <b-form-group
                  :label="$t('Branch')"
                  label-for="branch"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <v-select
                    v-model="reasonData.branch"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="branchOptions"
                    :reduce="val => val.value + '|' + val.label"
                    :clearable="false"
                    input-id="branch"
                  /> -->
                  <v-select
                    v-model="reasonData.branch"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="branchOptions"
                    :reduce="val => val.value"
                    multiple
                    input-id="branch"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancel"
            >
              Cancel
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import vSelect from '@/libs/vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router/index'
import reasonStoreModule from '../reasonStoreModule'
import storeModule from '../../../common/storeModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      required,
    }
  },
  computed: {
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'reason-list' })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'reason'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, reasonStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const blankData = {
      name: '',
      tag: [],
      branch: [],
    }

    const isJsonParsable = string => {
      try {
        JSON.parse(string)
      } catch (e) {
        return false
      }
      return true
    }

    const reasonData = ref(JSON.parse(JSON.stringify(blankData)))
    const objectId = router.currentRoute.params.id
    store.dispatch('reason/fetchOne', { id: objectId })
      .then(response => {
        const responseReasonData = {
          id: response.data.data.id,
          name: response.data.data.name,
          tag: isJsonParsable(response.data.data.tag) ? JSON.parse(response.data.data.tag) : response.data.data.tag,
          branch: isJsonParsable(response.data.data.branch) ? JSON.parse(response.data.data.branch) : response.data.data.branch,
        }
        reasonData.value = responseReasonData
      })
      .catch(error => {
        if (error.response.status === 404) {
          reasonData.value = undefined
        }
      })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(reasonData.value))
      store.dispatch('reason/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'reason-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const tagOptions = ref(JSON.parse(JSON.stringify([])))
    store
      .dispatch('common/fetchTags')
      .then(response => {
        const { tags } = response.data.data
        for (let i = 0; i < tags.length; i += 1) {
          tagOptions.value.push({ label: tags[i].name, value: tags[i].name })
        }
      })
      .catch(error => {
        console.log(error)
      })

    const branchOptions = ref(JSON.parse(JSON.stringify([])))
    store
      .dispatch('common/fetchBranches')
      .then(response => {
        const { branches } = response.data.data
        for (let i = 0; i < branches.length; i += 1) {
          branchOptions.value.push({ label: branches[i].name, value: branches[i].id })
        }
      })
      .catch(error => {
        console.log(error)
      })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      reasonData,
      onSubmit,
      tagOptions,
      branchOptions,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
